@tailwind base;
@tailwind components;
@tailwind utilities;


.audio-bars {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 50px;
    width: 100%;
}

.audio-bar {
    width: 5px;
    height: 100%;
    margin: 0 2px;
    background-color: #2edfba;
    animation: audio-bar-animation 0.8s infinite ease-in-out;
}

@keyframes audio-bar-animation {
    0%, 100% { height: 10%; }
    50% { height: 100%; }
}

.audio-bar:nth-child(1) { animation-delay: 0s; }
.audio-bar:nth-child(2) { animation-delay: 0.2s; }
.audio-bar:nth-child(3) { animation-delay: 0.4s; }
.audio-bar:nth-child(4) { animation-delay: 0.6s; }
.audio-bar:nth-child(5) { animation-delay: 0.8s; }
.audio-bar:nth-child(6) { animation-delay: 0.10s; }
.audio-bar:nth-child(7) { animation-delay: 0.8s; }
.audio-bar:nth-child(7) { animation-delay: 0.6s; }
.audio-bar:nth-child(8) { animation-delay: 0.4s; }
.audio-bar:nth-child(9) { animation-delay: 0.2s; }
.audio-bar:nth-child(10) { animation-delay: 0s; }



